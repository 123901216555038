<template>
<b-container>
  <b-form @submit.prevent="updateCompany">
    <div class="banner pt-4"> 
        <div v-if="isEdit">
          <b-form-group>
            <b-row class="upload-banner" @click="showUploadFile('banners')">
              <b-row class="upload-banner-inside">
                <b-col class="d-flex justify-content-center" cols="12"> <b-icon icon="card-image" style="width: 30px; height: 30px;"></b-icon></b-col>
                <b-col class="d-flex justify-content-center" cols="12"><p>Upload a banner image here</p></b-col>
              </b-row>
            </b-row>
            <b-img :src="payload.banners" class="img-fluid banner-preview"></b-img>
          </b-form-group>
          <b-form-group>
            <div class="upload-img-logo" @click="showUploadFile('logo')">
              <div class="d-flex justify-content-center mt-4"> <b-icon icon="card-image" style="width: 30px; height: 30px;"></b-icon></div>
              <div class="d-flex justify-content-center mt-2"><p>Upload a logo</p></div>
              <b-img :src="payload.logo" class="logo-preview"></b-img>
            </div>
          </b-form-group>
        </div>
        <b-img v-if="!isEdit" :src="company && company.banners" class="img-fluid"></b-img>
        <b-img v-if="!isEdit" :src="company && company.logo" class="card-img-logo"></b-img>
    </div>
    <div class="row">    
      <div class="content">
        <h3>
          {{ company && company.name && (company && company.name.th || company && company.name.en) }}
          <router-link to="/updatecompany" class="edit-button">
            <b-icon icon="pencil-square" style="color: #53A2DA;"></b-icon>
          </router-link>
          <!-- <a @click="isEdit = !isEdit" v-if="!isEdit" class="edit-button" href="/">
            <b-icon icon="pencil-square" style="color: #53A2DA;"></b-icon>
          </a> -->
          <div v-if="isEdit" class="d-flex justify-content-end">
            <b-button type="submit" size="md" variant="primary" style="width: 7rem;" class="mt-4 mr-3">
              save
            </b-button>
            <b-button
              size="md"
              type="button"
              variant="danger"
              class="mt-4"
              style="width: 7rem;"
              @click="isEdit = !isEdit"
            >
              cancel
            </b-button>
          </div>
        </h3>
        <p>{{ company && company.descriptions && (company.descriptions.th || company.descriptions.en) }}</p>
      </div>
    </div>
  </b-form>  
  <div class="row">    
    <div class="content">
      <router-link to="/createjob" class="col-sm-12 d-flex justify-content-end">
        <button class="btn btn-primary-light">สร้างตำแหน่งรับสมัครใหม่</button>
      </router-link>
      <div class="row">
        <div class="col-md-12 col-sm-12 mt-auto">
          <h4 class="m-0 pb-0 title text-bolder">ตำแหน่งงานที่เปิดรับสมัคร </h4>
        </div>
      </div>
      <divider :height="2" />
    </div>
    <div class="content mt-2 mb-2">
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-sm-12 mb-3"
          style="padding: .3rem !important;"
          v-for="(job, index) in jobs"
          :key="index"
        >
          <div class="card h-100">
            <div class="card-head">
              <router-link :to="`/detailjobs/${job._id}`">
                <img
                  v-if="job.company && job.company.banners"
                  class="card-img-top"
                  :src="job.company && job.company.banners"
                  :alt="job.title.th"
                />
                <div class="title text-bolder">
                  <p>
                    {{ job.company && job.company.name && (job.company.name.th || job.company.name.en) }}
                  </p>
                </div>
              </router-link>
            </div>
            <div class="card-body mb-2">
              <p class="card-title font-weight-bolder">{{ job.title.th }}</p>
              <p class="card-text text-limit">{{ job.descriptions.th }}</p>
            </div>
            <div class="card-bottom mb-2">
              <div class="card-bottom pl-3 pr-3 mb-2">
                <p class="card-text m-0">Salary: {{ job.salary.value }}</p>
                <p class="card-text">Location: {{ job.place.th }}</p>
              </div>
              <div class="d-flex justify-content-center pl-1 pr-1">
                <router-link :to="`/detailjobs/${job._id}`" class="m-auto p-1 text-center">
                  <button class="btn btn-danger btn-block btn-sm">ลบตำแหน่งงาน</button>
                </router-link>
                <router-link :to="`/detailjobs/${job._id}`" class="m-auto p-1 text-center">
                  <button class="btn btn-primary-light btn-block btn-sm">แก้ไข</button>
                </router-link>
                <router-link :to="`/detailjobs/${job._id}`" class="m-auto p-1 text-center">
                  <button class="btn btn-primary btn-block btn-sm">รายละเอียด</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>
      <pagination
        :totalData="totalJobs"
        :totalPage="totalPageJob"
        :currentPage="currentPageJob"
        @update:page="
          loadJobs({ page: $event })
          currentPageJob = $event
        "
      />
    </div>
    <div class="content">
      <div class="row m-1">
        <!-- <p class="m-0 title"> ข้อมูลผู้สมัครงาน </p> -->
        <h4 class="m-0 title text-bolder">ข้อมูลผู้สมัครงาน </h4>
        <div style="padding: 0 15px;"> 
          <span class="circle">{{ totalCantidates }}</span> 
        </div>
      </div>
      <divider :height="2" />
      <!-- <div class="row py-2">
        <div
          class="col-lg-4 col-md-6 col-sm-12 mb-3"
          v-for="(job, index) in jobs"
          :key="index"
        >
          <div class="card h-100">
            <div class="row m-1 mb-3 pt-4 pl-4 pr-4">
              <b-col md="4" class="card-avatar">
                <b-avatar v-if="!jobs.avatar" variant="primary" size="4em" src="https://www.hellokpop.com/wp-content/uploads/2021/01/jennie.jpg"></b-avatar>
              </b-col>
              <b-col md="8"  class="info-candidate">
                <p class="name">ชื่อ - นามสกุล</p>
                <p>นางสาว เจนนี่ คิม</p>
                <p class="align-item-center">
                  <img src="@/assets/images/calendar.png"  width="20" class="pr-1 pb-1"> 28 กุมภาพันธ์ 2564
                </p>
              </b-col>
            </div>
            <div class="card-body">
              <hr>
              <p class="card-title font-weight-bolder">{{ job.title.th }}</p>
              <p class="card-text text-limit">{{ job.descriptions.th }}</p>
              <div class="card-bottom">
                <div class="card-bottom">Salary: {{ job.salary.value }}</div>
                <div class="row">
                  <div class="col-7 text-limit">Location: {{ job.place.th }}</div>
                  <router-link :to="`/detailjobs/${job._id}`" class="col-5 text-right">
                    <button class="btn btn-primary btn-sm">รายละเอียด</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <my-upload
    field="file"
    @crop-upload-success="cropUploadSuccess"
    @crop-upload-fail="cropUploadFail"
    v-if="show"
    v-model="show"
    :width="cropScale.width"
    :height="cropScale.height"
    :noCircle="true"
    :noRotate="false"
    langType="en"
    :url="apiBaseUpload"
    img-format="png"
  ></my-upload>
</b-container>
</template>
<script>
import { getCompanyById, getCompanyJobs, updateCompanyProfile } from "@/services/company.service";
import Divider from "@/components/Divider";
import Pagination from "@/components/Pagination.vue"
import { apiUrl } from "@/configs";
import myUpload from "vue-image-crop-upload";
import _ from 'lodash';

export default {
  components: {
    Divider,
    Pagination,
    "my-upload": myUpload,
  },
  data() {
    return {
      apiBaseUpload: apiUrl + "/upload",
      companyId: null,
      jobs: [],
      company: null,
      totalJobs: 0,
      currentPageJob: 1,
      totalPageJob: 1,
      limit: 6,
      totalCantidates: 0,
      isEdit: false,
      show: false,
      uploadTarget: null,
      banners: null,
      payload: {}
    }
  },
  async mounted() {
    await this.loadCompany();
    this.loadJobs({ page: 1 })
  },
  watch: {
    isEdit() {
      if (!this.isEdit) {
        this.payload = _.cloneDeep(this.company);
        console.log(this.payload)
        console.log(this.isEdit)
      }
    },
  },
  computed: {
    cropScale() {
      let height = 720;
      let width = 720;
      if(this.uploadTarget === 'banners'){
        height = 517;
        width = 1130;
      }
      return { height, width };
    }
  },
  methods: {
    async loadCompany() {
      const { data } = await getCompanyById();
      if(!data) window.location.href = '/';
      this.companyId = data._id
      this.company = _.cloneDeep(data);
      this.payload = _.cloneDeep(data);
    },
    async updateCompany() {
      this.isEdit = false;
      await updateCompanyProfile(this.companyId, this.payload);
      await this.loadCompany()
    },
    async loadJobs(payload) {
      const { data } = await getCompanyJobs(this.companyId);
      this.totalJobs = data && data.total || data && data.length || 0;
      this.currentPageJob = payload.page;
      this.totalPageJob = this.totalJobs > 0 ? Math.ceil(this.totalJobs / this.limit) : 1
      this.jobs = data;
    },
    showUploadFile(uploadTarget) {
      this.uploadTarget = uploadTarget;
      this.show = true;
    },
    cropUploadSuccess(jsonData) {
      if (!this.uploadTarget) return;
      this.payload[this.uploadTarget] = jsonData.location;
      this.show = false;
    },
    cropUploadFail() {
      this.$toast.error("อัปโหลดล้มเหลว");
      this.show = false;
    },
  },
  
}
</script>

<style lang="scss" scoped>

.edit-button {
  font-size: 1.5rem;
  &:hover{
    cursor: pointer;
  }
}
.info-candidate {
  p {
    margin: 0 !important;
    font-size: 16px;
  }
  p.name {
    font-size: 14px;
  }
}

.card-avatar {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  justify-content: center;
  display: flex;
  padding: 5px;
  margin: auto;
  width: 5em;
  height: 4.5em;
}
hr {
  border: 1px solid #707070;
  margin-top: 0;
}
.btn-lg {
  font-size: 20px;
  font-weight: 100;
  padding: 0.2rem 1.2rem;
  background-color: #008FD2 !important;
}
.title-job {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.card {
  background: #ffffff;
  border-radius: 7px;
  transition: all 0.2s ease-in;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 20%);
  .card-head {
    position: relative;
    .card-img-logo {
      position: absolute;
      left: 1em;
      bottom: -5px;
      width: 25%;
      box-shadow: 1px 1px 10px #888888;
    }
    .title {
      position: absolute;
      bottom: 0;
      background: white;
      width: 100%;
      opacity:0.8;
      padding: 10px;
      p {
        color: #004D8D;
        text-align: end;
        margin: 0;
      }
    }
  }
  .card-body {
    padding: 0.5rem 1rem;
  }
  .card-bottom {
    bottom: 0;
    font-size: 1rem;
    font-weight: 300;
  }
  button {
    height: 30px;
    width: 90px;
    font-size: 14px;
  }
}
a {
  &:hover {
    text-decoration: none;
  }
}
.content {
  margin: 1rem;
  width: 100%;
  h3 {
    margin: 1rem 0;
    line-height: 30px;
  }
  .title {
    font-size: 1.5rem;
  }
}
.banner {
  position: relative;
  .banner-preview {
    position: absolute;
    opacity: .2;
    max-height: 300px;
    object-fit: cover;
    bottom: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
  .upload-banner {
    color: $primary-color;
    background: #F7F5F5;
    width: 100%;
    padding: 2rem;
    margin: 0;
    bottom: 1rem;
    height: 300px;
    .upload-banner-inside {
      width: 100%;
      border: 1px dashed #707070;
      border-radius: 10px;
      padding: 3rem 0;
      margin: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .img-fluid {
    width:100%;
    object-fit: cover;
    max-height: 300px;
  }
  .card-img-logo {
    position: absolute;
    right: 5vw;
    bottom: -4.5vw;
    height: 8rem;
    width: 8rem;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 17px;
  }
  .upload-img-logo {
    color: $primary-color;
    position: absolute;
    height: 8rem;
    width: 8rem;
    background: #F7F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D9D9D9;
    border-radius: 17px;
    right: 5vw;
    bottom: -3.5vw;
  }
  .logo-preview {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 17px;
    opacity: .2;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>